<template>
<v-section class="preparation">
  <prep-details></prep-details>
  <exam-prep></exam-prep>
</v-section>
</template>

<script>
import PrepDetails from "@/components/PrepDetails";
import ExamPrep from "@/components/ExamPrep";

export default {
  name: "Preparation",
  components: {
    PrepDetails,
    ExamPrep,
  },
};
</script>
