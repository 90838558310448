<template>
<v-app>
  <app-navigation></app-navigation>

  <v-content id="background" transition="slide-x-transition">
    <router-view></router-view>
  </v-content>

  <share-button></share-button>
  <Footer></Footer>
</v-app>
</template>

<script>
import AppNavigation from "@/components/AppNavigation";
import Footer from "@/components/Footer";
// import ShareButton from '@/components/ShareButton';

export default {
  name: "App",
  components: {
    AppNavigation,
    Footer,
    // ShareButton,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
#background {
  color: #616161 !important;
  font-family: Montserrat, sans-serif !important;
  background-position: center !important;
  background-size: cover !important;
  background: url('~@/assets/Backgrounds/Background image 2.jpeg');
}
</style>
