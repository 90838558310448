<template>
<v-container class="get-started pa-lg-6 pa-4">
  <h1 style="color: black !important;" class="text-center">{{GetStartedSection.title}}
  </h1>
  <v-layout wrap>
    <v-col cols="12" sm="12" md="4" class="" v-for="(item, index) in GetStartedSection.items" :key="index">
      <v-hover v-slot="{ hover }" close-delay="200">
        <router-link style="text-decoration: none; color: black;" :to="item.routerTarget">
          <v-card :elevation="hover ? 10 : 2" :class="{ 'on-hover': hover }" tile outlined>
            <v-img :src="letterIcon(item.img)" height="20vh">
            </v-img>
            <v-card-title>{{item.title}}
              <v-icon class="" color="light-blue">mdi-chevron-right</v-icon>
            </v-card-title>
          </v-card>
        </router-link>
      </v-hover>
    </v-col>
  </v-layout>
</v-container>
</template>

<script>
import GetStartedSection from '@/copy/GetStartedSection';

export default {
  name: 'GetStarted',
  data() {
    return {
      GetStartedSection,
    }
  },
  methods: {
    letterIcon: function(path) {
      return require("@/" + path);
    },
  },
};
</script>
