<template>
<v-container class="contact-details pa-lg-8 pa-4">
  <h1 style="color: black !important;" class="text-center">{{Contacts.title}}</h1>

  <v-layout wrap>
    <v-col cols="12" sm="12" md="6" v-for="(item, index) in Contacts.items" :key="index">
      <v-card elevation="2" tile outlined>
        <v-img :src="letterIcon(item.img)" height="50vh">
        </v-img>
        <v-card-title>{{item.prompt}}
          <v-icon class="px-1" color="light-blue">{{item.icon}}</v-icon>
        </v-card-title>
        <v-card-text>{{item.contact}}</v-card-text>
      </v-card>
    </v-col>
  </v-layout>

</v-container>
</template>

<script>
import Contacts from '@/copy/Contacts';

export default {
  name: 'ContactDetails',
  data() {
    return {
      Contacts,
    }
  },
  methods: {
    letterIcon: function(path) {
      return require("@/" + path);
    },
  },
};
</script>
