<template>
<span class="home-scroller">
  <v-virtual-scroll height="100" item-height="20">
  </v-virtual-scroll>
</span>
</template>

<script>
export default {
  name: 'Scroller',
  data() {
    return;
  }
}
</script>
