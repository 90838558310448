<template>
<v-footer class="footer" color="blue" padless>
  <v-card flat tile class="blue white--text text-center flex">
    <v-card-text>
      <v-btn v-for="link in links" :key="link" color="white" text rounded class="">
        <router-link style="text-decoration: none; color: inherit;" :to="link.routerTarget">
          {{ link.title }}
        </router-link>
      </v-btn>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text class="blue darken-2">
      <v-btn v-for="item in icons" :key="item" class="mx-4 white--text" icon>
        <a :href="item.url" target="_blank" style="text-decoration: none; color: inherit;">
          <v-icon>
            {{ item.icon }}
          </v-icon>
        </a>
      </v-btn>
    </v-card-text>
  </v-card>
</v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data: () => ({
    links: [{
        title: 'About Us',
        routerTarget: '/about'
      },
      {
        title: 'Resources',
        routerTarget: '/resources'
      },
      {
        title: 'Contact Us',
        routerTarget: '/contact'
      },
    ],
    icons: [{
        icon: 'mdi-facebook',
        url: 'https://www.facebook.com/people/Anando-Brahma/100070703492429/'
      },
      {
        icon: 'mdi-twitter',
        url: 'https://twitter.com/brahma_anando'
      },
      {
        icon: 'mdi-youtube',
        url: 'https://www.youtube.com/channel/UCJWOTMkR9O89WhLcSMcMSXQ'
      }
    ]
  }),
}
</script>
