<template>
<v-section class="contact">
  <contact-details></contact-details>
</v-section>
</template>

<script>
import ContactDetails from '@/components/ContactDetails';

export default {
  name: 'contact',
  components: {
    ContactDetails,
  }
};
</script>
