<template>
<v-section class="about">
  <Mission></Mission>
  <v-divider></v-divider>
  <get-started></get-started>
</v-section>
</template>

<script>
import Mission from '@/components/Mission';
import GetStarted from '@/components/GetStarted';

export default {
  name: 'About',
  components: {
    GetStarted,
    Mission
  }
};
</script>
