<template>
<v-container class="resources">
  <resource-details></resource-details>
  <exam-prep></exam-prep>
</v-container>
</template>

<script>
import ResourceDetails from "@/components/ResourceDetails";
import ExamPrep from "@/components/ExamPrep";

export default {
  name: "Resources",
  components: {
    ResourceDetails,
    ExamPrep,
  },
};
</script>
