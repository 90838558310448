<template>
<v-section class="home">
  <!-- class="app-background">-->
  <home-hero></home-hero>
  <v-divider></v-divider>
  <get-started></get-started>
</v-section>
</template>

<script>
import HomeHero from '@/components/HomeHero';
import GetStarted from '@/components/GetStarted';
//import Scroller from '@/components/Scroller';

export default {
  name: 'home',
  components: {
    HomeHero,
    GetStarted,
    //Scroller
  }
};
</script>

<style scoped>
.app-background {
  background: url('~@/assets/Background image 2.jpeg');
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>
