<template>
<v-section class="programs">
  <Timeline></Timeline>
  <v-divider></v-divider>
  <need-help></need-help>
</v-section>
</template>

<script>
import Timeline from '@/components/Timeline'
import NeedHelp from '@/components/NeedHelp'

export default {
  name: 'Programs',
  components: {
    Timeline,
    NeedHelp
  }
};
</script>
