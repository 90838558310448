<template>
<v-container class="exam-prep">
  <v-data-iterator class="py-2" :items="ExamMaterial.items" :items-per-page.sync="ExamMaterial.itemsPerPage" hide-default-footer>
    <template v-slot:default="props">
      <v-row>
        <v-col v-for="item in props.items" :key="item.name" cols="12" sm="6" md="4" lg="3">
          <v-card :color="item.color" elevation="4" dark>
            <v-card-title class="subheading font-weight-bold">
              {{ item.title }}
            </v-card-title>

            <v-divider></v-divider>

            <v-list class="white" v-for="resource in item.resources" :key="resource.name" dense>
              <v-list-item>
                <a style="text-decoration: none; color: inherit;" :href="resource.url" target="_blank">
                  <v-list-item-content class="black--text">
                    <p>{{resource.name}}
                      <v-icon color="black">{{resource.icon}}</v-icon>
                    </p>
                  </v-list-item-content>
                </a>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</v-container>
</template>

<script>
import ExamMaterial from '@/copy/ExamMaterial';

export default {
  name: 'ExamResouces',
  data() {
    return {
      ExamMaterial,
    }
  }
};
</script>
