<template>
<v-container>
  <div>
    <h1 color="" class="text-center">{{title}}</h1>
  </div>
  <v-list three-line>
    <template v-for="(item,index) in BlogPosts.posts">
      <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>
      <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>
      <v-list-item v-else :key="item.title">
        <v-list-item-content>
          <v-list-item-title v-html="item.title"></v-list-item-title>
          <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</v-container>
</template>

<script>
import BlogPosts from '@/store/BlogPosts';
console.log(BlogPosts);
export default {
  name: 'Scroller',
  data() {
    return {
      BlogPosts,
      title: 'Stories from our Enthusiasts'
    }
  }
};
</script>
