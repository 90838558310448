<template>
<v-container align-center class="pa-lg-8 pa-4" grid-list-lg>
  <v-layout wrap>
    <v-col cols="12" sm="12" md="6">
      <div style="color: black !important; font-family: Montserrat,sans-serif !important;" :class="{'display-3': $vuetify.breakpoint. lgAndUp, 'display-2': $vuetify.breakpoint. mdAndDown}" class="display-3 font-weight-black text-center">{{title}}
      </div>
      <div style="color: black !important; font-family: Montserrat,sans-serif !important;" :class="{'display-1': $vuetify.breakpoint. lgAndUp, 'display-1': $vuetify.breakpoint. mdAndDown}" class="display-1 text-center">{{title2}}
        <router-link style="text-decoration: none; color: black !important; font-family: Montserrat,sans-serif !important;" to="/programs">
          <p>Find out how
            <v-icon color="black" large>
              mdi-arrow-right
            </v-icon>
          </p>
        </router-link>
      </div>
    </v-col>
    <v-col cols="12" sm="12" md="6">
      <v-img height="100%" max-height="35vh" contain class="hidden-sm-and-down" :src="require('../assets/Hero Images/Sanskrit.jpeg')"></v-img>
    </v-col>
  </v-layout>
</v-container>
</template>

<script>
export default {
  name: 'HomeHero',
  data() {
    return {
      title: 'Your Guide to Learning Sanskrit',
      title2: 'Help us preserve our culture. Be part of something bigger.'
    }
  }
};
</script>
