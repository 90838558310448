<template>
<v-container class="timeline pa-4">
  <h1 style="color: black !important;" class="text-center">{{TimelineCards.title}}</h1>

  <v-timeline :dense="$vuetify.breakpoint.smAndDown">
    <v-timeline-item v-for="(item, i) in TimelineCards.items" :key="i" :color="item.color" :icon="item.icon" fill-dot class="py-10">
      <v-hover v-slot="{ hover }">
        <v-card :color="item.color" :elevation="hover ? 16 : 4" :class="{ 'on-hover': hover }" dark>
          <v-card-title class="text-h6">
            {{item.title}}
          </v-card-title>
          <v-card-text class="white text--primary py-2">
            <p v-html="item.description"></p>
            <p v-html="item.courseDetails"></p>
            <a :href="item.buttonURL" target="_blank" style="text-decoration: none; color: none;">
              <v-btn :color="item.color" outlined>
                {{item.button}}
              </v-btn>
            </a>
          </v-card-text>
        </v-card>
      </v-hover>
    </v-timeline-item>
  </v-timeline>
</v-container>
</template>

<script>
import TimelineCards from '@/copy/TimelineCards';

export default {
  name: 'Timeline',
  data() {
    return {
      TimelineCards,
    }
  }
};
</script>
