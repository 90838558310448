<template>
<span>
  <v-navigation-drawer app v-model="drawer" class="white" disable-resize-watcher>
    <v-list>
      <template v-for="(item, index) in items">
        <v-list-item-title :key="index">
          <router-link style="text-decoration: none; color: inherit;" :to="item.routerTarget">
            <v-list-item>
              {{item.title}}
            </v-list-item>
          </router-link>
        </v-list-item-title>
        <v-divider :key="`divider-${index}`"></v-divider>
      </template>
    </v-list>
  </v-navigation-drawer>
  <v-app-bar app color="white">
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>
    <v-spacer class="hidden-md-and-up"></v-spacer>
    <router-link style="text-decoration: none; color: inherit;" to="/">
      <v-btn text class="hidden-sm-and-down">HOME</v-btn>
    </router-link>
    <router-link style="text-decoration: none; color: inherit;" to="/programs">
      <v-btn text class="hidden-sm-and-down">PROGRAMS</v-btn>
    </router-link>
    <router-link style="text-decoration: none; color: inherit;" to="/prep">
      <v-btn text class="hidden-sm-and-down">PREPARATION</v-btn>
    </router-link>
    <router-link style="text-decoration: none; color: inherit;" to="/about">
      <v-btn text class="hidden-sm-and-down">OUR MISSION</v-btn>
    </router-link>
    <router-link style="text-decoration: none; color: inherit;" to="/contact">
      <v-btn text class="hidden-sm-and-down">CONTACT</v-btn>
    </router-link>
    <v-spacer class="hidden-sm-and-down"></v-spacer>
    <router-link style="text-decoration: none; color: inherit;" to="/">
      <img contain :src="require('../assets/Nav Logo.jpg')" max-height="10" max-width="30" />
    </router-link>
  </v-app-bar>
</span>
</template>

<script>
export default {
  name: 'AppNavigation',
  data() {
    return {
      appTitle: 'AnandoBrahma',
      drawer: false,
      items: [{
          title: 'Home',
          routerTarget: '/'
        },
        {
          title: 'Programs',
          routerTarget: '/programs'
        },
        {
          title: 'Preparation',
          routerTarget: '/prep'
        },
        {
          title: 'Our Mission',
          routerTarget: '/about'
        },
        {
          title: 'Contact',
          routerTarget: '/contact'
        }
      ]
    };
  }
};
</script>

<style scoped>

</style>
