<template>
<v-container class="mission pa-lg-8 pa-4">
  <div>
    <h1 style="color: black !important;" class="text-center py-lg-2">{{AboutUs.abouttitle}}</h1>
    <v-layout wrap>
      <v-col cols="12" sm="12" md="6" class="px-lg-2">
        <div v-html="AboutUs.aboutUs" class=""></div>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="px-lg-2">
        <v-img height="40vh" class="hidden-sm-and-down" :src="require('../assets/Team.jpeg')"></v-img>
      </v-col>
    </v-layout>
  </div>
  <div class="py-lg-4">
    <h1 style="color: black !important;" class="text-center pa-lg-2">{{AboutUs.missiontitle}}</h1>
    <v-layout wrap>
      <v-col cols="12" sm="12" md="6" class="px-lg-2">
        <v-img height="50vh" class="hidden-sm-and-down" :src="require('../assets/Vision.jpeg')"></v-img>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="px-lg-2">
        <p style="color: black !important;" v-html="AboutUs.missionStmt"></p>
        <div v-html="AboutUs.mission" class=""></div>
      </v-col>
    </v-layout>
  </div>
</v-container>
</template>

<script>
import AboutUs from '@/copy/AboutUs';

export default {
  name: 'Mission',
  data() {
    return {
      AboutUs,
    }
  }
};
</script>
