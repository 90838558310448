<template class="share-button">
<div>
  <v-btn @click="snackbar = true" color="pink" elevation="10" fab fixed right bottom>
    <v-icon color="white">
      mdi-share-variant
    </v-icon>
  </v-btn>
  <v-snackbar v-model="snackbar">
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</div>
</template>

<script>
export default {
  name: 'ShareButton',
  data: () => ({
    snackbar: false,
    text: `Coming soon!`,
  }),
};
</script>
