<template>
<v-container class="pa-lg-4 pa-2">
  <h1 style="color: black !important;" class="text-center">{{title}}</h1>
</v-container>
</template>

<script>
export default {
  name: 'PrepDetails',
  data() {
    return {
      title: 'Preparation'
    };
  }
};
</script>
